import React from "react"
import { graphql, PageProps } from "gatsby"
import LayoutContainer from "@containers/Layout"
import SEO from "@components/SEO"
import { sanitize } from "@helpers/helpers"
import { TermsAndConditionsDataType } from "@typings/requests/terms-and-conditions"
import "./terms-and-conditions.scss"

const TermsAndConditions: React.FC<PageProps<TermsAndConditionsDataType>> =
  props => {
    const {
      allStrapiTermsAndConditionsPage: {
        nodes: [termsAndConditionsData],
      },
    } = props.data
    const { header, text, title, description, metaImage, locale } =
      termsAndConditionsData
    return (
      <>
        <SEO
          title={title}
          description={description}
          image={metaImage.localFile.publicURL}
          lang={locale}
          pathname={props.location.pathname}
        />
        <LayoutContainer
          className="terms"
          location={props.location}
          lang="es"
          colorLangSwitcher="black"
        >
          <section className="container terms__header">
            <h1 className="terms__title container__item text text--h1">
              {header}
            </h1>
          </section>
          <section className="container">
            <div
              className="container__item terms__info text"
              dangerouslySetInnerHTML={{
                __html: sanitize(text.data.text),
              }}
            />
          </section>
        </LayoutContainer>
      </>
    )
  }

export const query = graphql`
  {
    allStrapiTermsAndConditionsPage(filter: { locale: { eq: "es" } }) {
      nodes {
        locale
        header
        description
        text {
          data {
            text
          }
        }
        title
        metaImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`

export default TermsAndConditions
